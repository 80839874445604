<template>
  <div class="app-snackbar">
    <v-snackbar
      v-model="snackbar"
      :timeout="-1"
      bottom
      left
      color="#f2f2f2"
      elevation="2"
    >
      <div :style="{ color: color }" class="sw-small">
        {{ message }}
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          :style="{ color: color }"
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import EventBus from "@/utils/eventBus";

export default {
  data: () => ({
    snackbar: false,
    type: "",
    message: "",
  }),
  computed: {
    color() {
      return this.type === "error" ? "#ff5252" : this.gmPrimaryColor;
    },
  },
  mounted() {
    EventBus.$on("showNotification", (notification) =>
      this.showNotification(notification),
    );
  },
  methods: {
    showNotification(notification) {
      setTimeout(() => {
        this.snackbar = false;
      }, notification.timeout || 10000);

      this.type = notification.type;
      this.message = notification.message;
      this.snackbar = true;
    },
  },
};
</script>

<style lang="scss">
.app-snackbar {
  .v-snack__content {
    max-width: 400px;
  }
}
</style>
